/**
* Generated automatically at built-time (2025-04-02T06:01:20.679Z).
* DO NOT CHANGE IT, it will be automatically overwritten with each build.
*/
import { createLazyTemplateItem } from "../utils";
import { mergeTemplateItems } from "../common/utils";
export default function Template (props){
const defaultTemplate = [...props.widgets];
return mergeTemplateItems(defaultTemplate, props.templates).map(createLazyTemplateItem);
}
Template.mapValueToTemplateProps = {pageKey: "custom-made-vinkoleskab",templateKey: "sites/72-16011d62-f7e1-4d13-a13c-aca306244ecc"};